import { Component, OnInit } from '@angular/core';
import { CampaignDto } from './dto/campaign';
import { CampaignsService } from './services/campaigns.service';
import { AppMessageService } from '../../services/common/app-message.service';
import { OccurenceParameterService } from '../../services/occurence-parameter.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  campaigns: any[] = [];
  dialog: boolean = false;
  variables: any[] = [];
  data: CampaignDto = {};  // Initialize an empty object
  maxKampanyaSMSKarakteri: number = 746;
  templateCharacterCount: number = 0;
  smsCountMessage: string = 'SMS Karakter Sınırı Aşıldı. SMS Gönderiminde Sorun Yaşayabilirsiniz!'
  campaignSmsTextCount: number = 0;
  numberAvailableCharacters: number = 0;


  constructor(
    private campaignsService: CampaignsService,
    private messageService: AppMessageService,
    private readonly parameterService: OccurenceParameterService,

  ) { }


  ngOnInit() {
    this.loadData();
    this.variables = [
      { name: 'Kampanya Kodu', code: '[kampanyaKodu]' },
      { name: 'Kampanya Tutarı', code: '[kampanyaTutari]' },
      { name: 'Sigoratalı Adı Soyadı', code: '[adSoyad]' },
      { name: 'Hizmet Numarası', code: '[hizmetNo]' },
      { name: 'Plaka Numarası', code: '[plaka]' },
      { name: 'Hizmet Adı', code: '[hizmetTipiAdi]' }
    ];
  }

  loadData() {
    this.campaignsService.getAll().subscribe((data: any[]) => {
      this.campaigns = data;
    });
  }

  async openDialog(data?: CampaignDto) {
    this.maxKampanyaSMSKarakteri = Number((await this.parameterService.getParameterByName('MaxKampanyaSMSKarakteri').toPromise())?.value);
    this.data.templateCharacterCount = 0;
    if (this.data.campaignSmsText) {
      this.onTextChange()
    }

    this.data = {}
    if (data?._id) {
      this.data = data
    }
    this.dialog = true;
  }

  saveOrUpdate() {
    this.onTextChange();
    if (this.data?._id) {
      if (this.numberAvailableCharacters < 0) {
        this.messageService.confirm({
          message: `SMS Karakter Sınırı Aşıldı. SMS Gönderiminde Sorun Yaşayabilirsiniz! Mesajı Göndermek İstiyor musunuz?`,
          header: `Bilgi Mesajı!`,
          accept: () => {
            this.campaignsService.update(this.data._id, this.data).subscribe(response => {

              this.messageService.successToast('Başarılı', 'Kampanya başarılı bir şekilde güncellenmiştir.');
              this.dialog = false;
              this.loadData();
            }, error => {
              this.messageService.errorToast('Başarısız', 'Kampanya güncellenemedi.');
            });

          }
        });
        return
      }

      // Kampanya mevcutsa güncelleme işlemi yapılır
      this.campaignsService.update(this.data._id, this.data).subscribe(response => {
        this.messageService.successToast('Başarılı', 'Kampanya başarılı bir şekilde güncellenmiştir.');
        this.dialog = false;
        this.loadData();
      }, error => {
        this.messageService.errorToast('Başarısız', 'Kampanya güncellenemedi.');
      });
    } else {
      if (!this.data.campaignName) {
        this.messageService.reportError('Kampanya Adı zorunludur.')

      } else if (!this.data.nocTemplateId) {
        this.messageService.reportError('NOC Template ID zorunludur')

      } else if (!this.templateCharacterCount) {
        this.messageService.reportError('Template Karakter sayısı zorunludur')

      } else if (!this.data.campaignSmsText) {
        this.messageService.reportError('Kampanya SMS Metni zorunludur.')

      } else {
        // Yeni kampanya oluşturma işlemi yapılır

        if (this.numberAvailableCharacters < 0) {
          this.messageService.confirm({
            message: `SMS Karakter Sınırı Aşıldı. SMS Gönderiminde Sorun Yaşayabilirsiniz! Mesajı Göndermek İstiyor musunuz?`,
            header: `Bilgi Mesajı!`,
            accept: () => {
              this.campaignsService.create(this.data).subscribe(response => {
                this.messageService.successToast('Başarılı', 'Kampanya başarılı bir şekilde tanımlanmıştır.');
                this.dialog = false;
                this.loadData();
              }, error => {
                this.messageService.errorToast('Başarısız', 'Kampanya tanımlanamadı.');
              });
            }
          });
          return
        }

        this.campaignsService.create(this.data).subscribe(response => {
          this.messageService.successToast('Başarılı', 'Kampanya başarılı bir şekilde tanımlanmıştır.');
          this.dialog = false;
          this.loadData();
        }, error => {
          this.messageService.errorToast('Başarısız', 'Kampanya tanımlanamadı.');
        });
      }
    }
  }

  onTextChange() {
    this.campaignSmsTextCount = this.data.campaignSmsText.length;
    this.numberAvailableCharacters = this.maxKampanyaSMSKarakteri - this.campaignSmsTextCount - this.data.templateCharacterCount;
  }

}